<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.chancellor.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.chancellor.list.title"></app-i18n>
      </h1>

      <app-chancellor-list-toolbar></app-chancellor-list-toolbar>
      <app-chancellor-list-filter></app-chancellor-list-filter>
      <app-chancellor-list-table></app-chancellor-list-table>
    </div>
  </div>
</template>

<script>
import ChancellorListFilter from '@/modules/chancellor/components/chancellor-list-filter.vue';
import ChancellorListTable from '@/modules/chancellor/components/chancellor-list-table.vue';
import ChancellorListToolbar from '@/modules/chancellor/components/chancellor-list-toolbar.vue';

export default {
  name: 'app-chancellor-list-page',

  components: {
    [ChancellorListFilter.name]: ChancellorListFilter,
    [ChancellorListTable.name]: ChancellorListTable,
    [ChancellorListToolbar.name]: ChancellorListToolbar,
  },
};
</script>

<style>
</style>
